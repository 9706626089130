import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, mapTo, throwError, } from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class DataService {
    purchaseUrl=`https://auraconst-e5e30-default-rtdb.firebaseio.com/journalEntry.json`;
    productUrl=`https://auraconst-e5e30-default-rtdb.firebaseio.com/labour.json`;
    supplierCatIdUrl=`https://auraconst-e5e30-default-rtdb.firebaseio.com/journalEntry`;
    employeeUrl=`https://auraconst-e5e30-default-rtdb.firebaseio.com/employee.json`;
    partnerUrl=`https://auraconst-e5e30-default-rtdb.firebaseio.com/partner.json`;
    supplierUrl=`https://auraconst-e5e30-default-rtdb.firebaseio.com/supplier.json`;
    productsUrl=`https://auraconst-e5e30-default-rtdb.firebaseio.com/product.json`;
    projectUrl=`https://auraconst-e5e30-default-rtdb.firebaseio.com/project.json`;
    

    constructor(private http:HttpClient) {}

    create(data: any) {
        return this.http.post(`${this.purchaseUrl}`, data).pipe(
           mapTo(true),
          catchError(error => {
            return error;
          })
        );
      }
      get(): Observable<any> {
        return this.http.get(this.purchaseUrl).pipe(
          catchError(error => {
            return throwError(error);
          })
        );
      }
      getProduct(): Observable<any> {
        return this.http.get(this.productUrl).pipe(
          catchError(error => {
            return throwError(error);
          })
        );
      }
      getEmployee(): Observable<any> {
        return this.http.get(this.employeeUrl).pipe(
          catchError(error => {
            return throwError(error);
          })
        );
      }
      getPartner(): Observable<any> {
        return this.http.get(this.partnerUrl).pipe(
          catchError(error => {
            return throwError(error);
          })
        );
      }
      getSupplier(): Observable<any> {
        return this.http.get(this.supplierUrl).pipe(
          catchError(error => {
            return throwError(error);
          })
        );
      }
      getProducts(): Observable<any> {
        return this.http.get(this.productsUrl).pipe(
          catchError(error => {
            return throwError(error);
          })
        );
      }
      getProject(): Observable<any> {
        return this.http.get(this.projectUrl).pipe(
          catchError(error => {
            return throwError(error);
          })
        );
      }

      update(data:any,id: any): Observable<any | boolean> {
        return this.http.put(`${this.supplierCatIdUrl}/${id}.json`,data).pipe(
          map((value: any) => {
            console.log(value)
            return value;
          }),
          catchError(error => {
            return error;
          })
        );
      }
      getById(id: any): Observable<any | boolean> {
        return this.http.get(`${this.supplierCatIdUrl}/${id}.json`).pipe(
          map((value: any) => {
            console.log(value)
            return value;
          }),
          catchError(error => {
            return error;
          })
        );
      }
      deleteById(id: any): Observable<any | boolean> {
        return this.http.delete(`${this.supplierCatIdUrl}/${id}.json`).pipe(
          map((value: any) => {
            console.log(value)
            return value;
          }),
          catchError(error => {
            return error;
          })
        );
      }
}