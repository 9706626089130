<div class="container-fluid">
    <h3>Filters</h3>
      <div class="filters">
       
          <label for="supplier">Supplier Name</label>
          <select id="supplier" class="dropdown">
              <!-- Add options here -->
          </select>
          <label for="category">Category</label>
          
          <select id="category" class="dropdown">
              <!-- Add options here -->
          </select>
          <label for="location">Location</label>
         
          <select id="location" class="dropdown">
              <!-- Add options here -->
          </select>
          <button id="add-button" class="add-button" (click)="add()">Add +</button>
      </div>
      <div class="scroll">
        <table
    class="custom-table">
    <thead>
      <tr>
        
        <th>Type</th>
        <th>Amount</th>
        <th>Date</th>
        <th>Purpose</th>
        <th>Action</th>
        </tr>
        </thead>
        <tbody >
            <tr
            *ngFor="
            let element of labourList
            | paginate: { itemsPerPage: 25, currentPage: page }
          "
            >
                
              <td>{{element.amountType}}</td>
              <td>{{element.amount}}</td>
              <td>{{element.date}}</td>
              <td>{{element.purpose}}</td>
            
              <td>
                <mat-icon (click)="onEdit(element.id)">  edit</mat-icon>
                <mat-icon (click)="onDelete(element.id)"> delete</mat-icon>
              </td>
            </tr>
            </tbody>
            </table>
     </div>
  </div>