import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { NotificationService } from '../../../services/notificationService';
import { DataService } from '../data.service';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrl: './edit.component.scss'
})
export class EditComponent {
  project: any = {};
  categoryData: string[] = [];
  states: string[] = ['State 1', 'State 2', 'State 3'];
  labourForm: FormGroup = new FormGroup({});
  submitted: boolean = false;
  supplierName:string[]=[];
  keyword1="labourName";
  labourName:any[]=[];
  id:any;
  categoryName:any;
  supplier:any[]=[];
  product:any[]=[];
  keyword2="employeeName";
  keyword3="partnerName";
  keyword4="supplierName";
  keyword5="projectName";
  keyword6="productName";
  employeeName:any[]=[];
  partnerName:any[]=[];
  projectName:any[]=[];
  productName:any[]=[];
  //id:any;

  isEnabled=true;
  constructor(private data: DataService,
    private formBuilder: FormBuilder,
    private toastr:NotificationService,
    private router:Router,
    private route:ActivatedRoute
  ) {}

  ngOnInit()  {
    if (this.route.snapshot.queryParamMap.get("id")) {
      // @ts-ignore TS2322
      this.id = this.route.snapshot.queryParamMap.get("id");
      this.getById(this.id);
    }

    this.labourForm= this.formBuilder.group({
      labourDebit: ['Wages'],
      employeeDebit:['Salary'],
      partnerDebit:['',Validators.required],
      supplierDebit:['',Validators.required],
      labourName: ['', Validators.required],
      employeeName:[''],
      partnerName:[''],
      supplierName:[''],
      totalAmount: ['',Validators.required],
      tds: ['',Validators.required],
      amount: ['',Validators.required],
      date: ['', Validators.required],
      purpose: ['', Validators.required],
      
      
    });
  }

  getById(_id:any){
    this.data.getById(_id).subscribe((res:any)=>{
      console.log(res,"res")
     if(res){
      //console.log(this.categoryData,"concel")
      this.updateView(res)
     }
    })
    
  }
  updateView(data:any){
    console.log(data,"Edit")
    this.labourForm.patchValue({
      labourDebit: data.labourDebit,
      employeeDebit:data.employeeDebit,
      partnerDebit:data.partnerDebit,
      supplierDebit:data.supplierDebit,
      labourName:data.labourName,
      employeeName:data.employeeName,
      partnerName:data.partnerName,
      supplierName:data.supplierName,
      totalAmount: data.totalAmount,
      tds:data.tds,
      amount: data.amount,
      date: data.date,
      purpose:data.purpose,
    })
  }
 

  goBack() {
    this.router.navigate(['/dashboard/journalEntry']);
  }
 
  get f() {
    return this.labourForm.controls;
  }

  onSubmit(data: any) {
    if (this.labourForm.invalid) {
      this.toastr.showError("Error", "");
      return;
    }

    data.updateOn=moment().format()
    //data.isActive = true;
    
    this.data.update(data,this.id).subscribe((res:any)=>{
      console.log('Project Created!',res);
      this.toastr.showSuccess("Successfully!!","Project Added")
      this.router.navigate(['/dashboard/journalEntry'])
    })
  }
  selectEvent1(item:any) {
    // do something with selected item
    console.log(item)
    this.id = item.id
    this.labourForm.patchValue({
      labourName:item.labourName
    })
  }
 
  onChangeSearch1(search: string) {
    // fetch remote data from here
    console.log(search)
    search.toLowerCase();
    this.data.getProduct().subscribe((val:any)=>{
      this.labourName = Object.keys(val).map(key => ({ id: key, ...val[key] }));
      //console.log(this.productName)
      return this.labourName.filter(val => val.productName.toLowerCase().includes(search))
    })
  }

  onFocused1(e:any) {
    console.log(e)
    // do something
  }
  selectEvent2(item:any) {
    // do something with selected item
    console.log(item)
    this.id = item.id
    this.labourForm.patchValue({
      employeeName:item.employeeName
    })
  }
 
  onChangeSearch2(search: string) {
    // fetch remote data from here
    console.log(search)
    search.toLowerCase();
    this.data.getEmployee().subscribe((val:any)=>{
      this.employeeName = Object.keys(val).map(key => ({ id: key, ...val[key] }));
      //console.log(this.productName)
      return this.employeeName.filter(val => val.toLowerCase().includes(search))
    })
  }

  onFocused2(e:any) {
    console.log(e)
    // do something
  }
  selectEvent3(item:any) {
    // do something with selected item
    console.log(item)
    this.id = item.id
    this.labourForm.patchValue({
      supplierName:item.supplierName
    })
  }
 
  onChangeSearch3(search: string) {
    // fetch remote data from here
    console.log(search)
    search.toLowerCase();
    this.data.getSupplier().subscribe((val:any)=>{
      this.supplierName = Object.keys(val).map(key => ({ id: key, ...val[key] }));
      //console.log(this.productName)
      return this.supplierName.filter(val => val.toLowerCase().includes(search))
    })
  }

  onFocused3(e:any) {
    console.log(e)
    // do something
  }
  selectEvent4(item:any) {
    // do something with selected item
    console.log(item)
    this.id = item.id
    this.labourForm.patchValue({
      partnerName:item.partnerName
    })
  }
 
  onChangeSearch4(search: string) {
    // fetch remote data from here
    console.log(search)
    search.toLowerCase();
    this.data.getPartner().subscribe((val:any)=>{
      this.partnerName = Object.keys(val).map(key => ({ id: key, ...val[key] }));
      //console.log(this.productName)
      return this.partnerName.filter(val => val.toLowerCase().includes(search))
    })
  }

  onFocused4(e:any) {
    console.log(e)
    // do something
  }

  onCancel(){
    this.labourForm.reset()
  }
  selectEvent(item:any) {
    // do something with selected item
    console.log(item)
    this.categoryData=[];
  //  this.data.getCategory().subscribe((res:any)=>{
  //   this.product = Object.keys(res).map(key => ({ id: key, ...res[key] }));
  //   this.product.forEach((res:any)=>{
  //   if (res.supplierName && res.supplierName.toLowerCase().trim() === item.supplierName.toLowerCase().trim()) {
  //     this.categoryData.push(res.productName)
  //     console.log(this.categoryData)
  //   }
  // })
  //  })
   
  
   
    this.labourForm.patchValue({
     
      shortName:item.shortName,
      supplierName:item.supplierName
    })
  }
 
  onChangeSearch(search: string) {
    // fetch remote data from here
    console.log(search)
    search.toLowerCase();
    // this.data.getCategory().subscribe((res:any)=>{
    //   this.supplierName = Object.keys(res).map(key => ({ id: key, ...res[key] }));
    //       console.log(this.supplierName)
    //     return this.supplierName.filter( val  =>   val.toLowerCase().includes(search));
    // })    
  }

  onFocused(e:any) {
    console.log(e)
    // do something
  }
  selectEvent5(item:any) {
    // do something with selected item
    console.log(item)
    this.id = item.id
    this.labourForm.patchValue({
      projectName:item.projectName
    })
  }
 
  onChangeSearch5(search: string) {
    // fetch remote data from here
    console.log(search)
    search.toLowerCase();
    this.data.getProject().subscribe((val:any)=>{
      this.projectName = Object.keys(val).map(key => ({ id: key, ...val[key] }));
      //console.log(this.productName)
      return this.projectName.filter(val => val.toLowerCase().includes(search))
    })
  }

  onFocused5(e:any) {
    console.log(e)
    // do something
  }
  selectEvent6(item:any) {
    // do something with selected item
    console.log(item)
    this.id = item.id
    this.labourForm.patchValue({
      productName:item.productName
    })
  }
 
  onChangeSearch6(search: string) {
    // fetch remote data from here
    console.log(search)
    search.toLowerCase();
    this.data.getProducts().subscribe((val:any)=>{
      this.productName = Object.keys(val).map(key => ({ id: key, ...val[key] }));
      //console.log(this.productName)
      return this.productName.filter(val => val.toLowerCase().includes(search))
    })
  }

  onFocused6(e:any) {
    console.log(e)
    // do something
  }

}
