<div class="form-container">
    <button class="back-button" (click)="goBack()">
        <mat-icon>arrow_back</mat-icon>
    </button>
    <h2>New JournalEntry</h2>
    <mat-tab-group>
        <mat-tab label="Labour">
            <form [formGroup]="labourForm" >
                <div class="form-row">
                  <div class="form-group col-sm-12 col-md-4">
                      <label for="labourDebit"> Debit<span class="requried-field">*</span></label>
                      <input
                      readonly
                      id="labourDebit"
                      placeholder="Enter labourDebit"
                      class="form-control"
                      formControlName="labourDebit"
                      [ngClass]="{ 'is-invalid': submitted && f['labourDebit'].errors }"
                    />
                    </div>
                  <div class="form-group col-sm-12 col-md-4">
                    <label for="labourName">LabourName<span class="requried-field">*</span></label>
                    <ng-autocomplete
                      [data]="labourName"
                      [searchKeyword]="keyword1"
                      placeholder="Enter the labourName"
                      (selected)='selectEvent1($event)'
                      (inputChanged)='onChangeSearch1($event)'
                      (inputFocused)='onFocused1($event)'
                      historyIdentifier="labourName"
                      [itemTemplate]="itemTemplate1"
                      [notFoundTemplate]="notFoundTemplate">
                    </ng-autocomplete>
                  
                    <ng-template #itemTemplate1 let-item>
                      <a [innerHTML]="item.labourName"></a>
                    </ng-template>
                  
                    <ng-template #notFoundTemplate let-notFound>
                      <div [innerHTML]="notFound"></div>
                    </ng-template>
                    <div *ngIf="submitted && f['labourName'].errors" class="invalid-feedback">
                      <div *ngIf="f['labourName'].errors['required']">Labour Name is required</div>
                    </div>
                  </div>
                  <div class="form-group col-sm-12 col-md-4">
                    <label for="totalAmount">Total Amount</label>
                    <input
                      type="number"
                      id="totalAmount"
                      placeholder="Enter totalAmount"
                      class="form-control"
                      formControlName="totalAmount"
                      [ngClass]="{ 'is-invalid': submitted && f['totalAmount'].errors }"
                    />
                    <div *ngIf="submitted && f['totalAmount'].errors" class="invalid-feedback">
                      <div *ngIf="f['totalAmount'].errors['required']">Total Amount is required</div>
                      
                    </div>
                  </div>
                  <div class="form-group col-sm-12 col-md-4">
                    <label for="tds">TDS<span class="requried-field">*</span></label>
                    <input
                      type="text"
                      id="tds"
                      placeholder="Enter tds"
                      class="form-control"
                      formControlName="tds"
                      [ngClass]="{ 'is-invalid': submitted && f['tds'].errors }"
                    />
                    <div *ngIf="submitted && f['tds'].errors" class="invalid-feedback">
                      <div *ngIf="f['tds'].errors['required']">tds is required</div>
                    </div>
                  </div>
            
                  <div class="form-group col-sm-12 col-md-4">
                    <label for="amount">Amount</label>
                    <input
                      type="number"
                      id="amount"
                      placeholder="Enter amount"
                      class="form-control"
                      formControlName="amount"
                      [ngClass]="{ 'is-invalid': submitted && f['amount'].errors }"
                    />
                    <div *ngIf="submitted && f['amount'].errors" class="invalid-feedback">
                      <div *ngIf="f['amount'].errors['amount']">Amount is required</div>
                      
                    </div>
                  </div>
                  <div class="form-group col-sm-12 col-md-4">
                    <label for="date">Date</label>
                    <input
                      type="date"
                      id="date"
                      placeholder="Enter date"
                      class="form-control"
                      formControlName="date"
                      [ngClass]="{ 'is-invalid': submitted && f['date'].errors }"
                    />
                    <div *ngIf="submitted && f['date'].errors" class="invalid-feedback">
                      <div *ngIf="f['date'].errors['required']">Date is required</div>
                      
                    </div>
                  </div>
            
                  <div class="form-group col-sm-12 col-md-4">
                    <label for="purpose"> Purpose <span class="requried-field">*</span></label>
                    <input
                      type="text"
                      id="purpose"
                      placeholder="Enter purpose"
                      class="form-control"
                      formControlName="purpose"
                      [ngClass]="{ 'is-invalid': submitted && f['purpose'].errors }"
                    />
                    <div *ngIf="submitted && f['purpose'].errors" class="invalid-feedback">
                      <div *ngIf="f['purpose'].errors['required']">Purpose is required</div>
                    
                    </div>
                  </div>
                  <!-- <div class="form-group col-sm-12 col-md-12 col-lg-4">
                    <label for="sstatus">Status<span class="requried-field">*</span></label>
                    <mat-slide-toggle class="slide-enable" [color]="isEnabled ? ' rgba(0, 128, 0, 0.5) ' : 'default'" (change)="enabled()">Enable</mat-slide-toggle>
                  </div> -->
                </div>
                <div class="formbutton">
          
               
                <button type="submit" class="btn btn-primary" (click)="onSubmit(labourForm.value)">Submit</button>
                <button type="submit" class="btn btn-danger btn2"  (click)="onCancel()">Cancel</button>
              </div>
              </form>
        </mat-tab>
        <mat-tab label="Employee">
            <form [formGroup]="labourForm" >
                <div class="form-row">
                  <div class="form-group col-sm-12 col-md-4">
                      <label for="employeeDebit"> Debit<span class="requried-field">*</span></label>
                      <input
                      readonly
                      id="employeeDebit"
                      placeholder="Enter employeeDebit"
                      class="form-control"
                      formControlName="employeeDebit"
                      [ngClass]="{ 'is-invalid': submitted && f['employeeDebit'].errors }"
                    />
                    </div>
                  <div class="form-group col-sm-12 col-md-4">
                    <label for="employeeName">Employee Name<span class="requried-field">*</span></label>
                    <ng-autocomplete
                      [data]="employeeName"
                      [searchKeyword]="keyword2"
                      placeholder="Enter the Employee Name"
                      (selected)='selectEvent2($event)'
                      (inputChanged)='onChangeSearch2($event)'
                      (inputFocused)='onFocused2($event)'
                      historyIdentifier="employeeName"
                      [itemTemplate]="itemTemplate2"
                      [notFoundTemplate]="notFoundTemplate">
                    </ng-autocomplete>
                  
                    <ng-template #itemTemplate2 let-item>
                      <a [innerHTML]="item.employeeName"></a>
                    </ng-template>
                  
                    <ng-template #notFoundTemplate let-notFound>
                      <div [innerHTML]="notFound"></div>
                    </ng-template>
                    <div *ngIf="submitted && f['employeeName'].errors" class="invalid-feedback">
                      <div *ngIf="f['employeeName'].errors['required']"> Name is required</div>
                    </div>
                  </div>
                  <div class="form-group col-sm-12 col-md-4">
                    <label for="totalAmount">Total Amount</label>
                    <input
                      type="number"
                      id="totalAmount"
                      placeholder="Enter totalAmount"
                      class="form-control"
                      formControlName="totalAmount"
                      [ngClass]="{ 'is-invalid': submitted && f['totalAmount'].errors }"
                    />
                    <div *ngIf="submitted && f['totalAmount'].errors" class="invalid-feedback">
                      <div *ngIf="f['totalAmount'].errors['required']">Total Amount is required</div>
                      
                    </div>
                  </div>
                  <div class="form-group col-sm-12 col-md-4">
                    <label for="tds">TDS<span class="requried-field">*</span></label>
                    <input
                      type="text"
                      id="tds"
                      placeholder="Enter tds"
                      class="form-control"
                      formControlName="tds"
                      [ngClass]="{ 'is-invalid': submitted && f['tds'].errors }"
                    />
                    <div *ngIf="submitted && f['tds'].errors" class="invalid-feedback">
                      <div *ngIf="f['tds'].errors['required']">tds is required</div>
                    </div>
                  </div>
            
                  <div class="form-group col-sm-12 col-md-4">
                    <label for="amount">Amount</label>
                    <input
                      type="text"
                      id="amount"
                      placeholder="Enter amount"
                      class="form-control"
                      formControlName="amount"
                      [ngClass]="{ 'is-invalid': submitted && f['amount'].errors }"
                    />
                    <div *ngIf="submitted && f['amount'].errors" class="invalid-feedback">
                      <div *ngIf="f['amount'].errors['amount']">Amount is required</div>
                      
                    </div>
                  </div>
                  <div class="form-group col-sm-12 col-md-4">
                    <label for="date">Date</label>
                    <input
                      type="text"
                      id="date"
                      placeholder="Enter date"
                      class="form-control"
                      formControlName="date"
                      [ngClass]="{ 'is-invalid': submitted && f['date'].errors }"
                    />
                    <div *ngIf="submitted && f['date'].errors" class="invalid-feedback">
                      <div *ngIf="f['date'].errors['required']">Date is required</div>
                      
                    </div>
                  </div>
            
                  <div class="form-group col-sm-12 col-md-4">
                    <label for="purpose"> Purpose <span class="requried-field">*</span></label>
                    <input
                      type="text"
                      id="purpose"
                      placeholder="Enter purpose"
                      class="form-control"
                      formControlName="purpose"
                      [ngClass]="{ 'is-invalid': submitted && f['purpose'].errors }"
                    />
                    <div *ngIf="submitted && f['purpose'].errors" class="invalid-feedback">
                      <div *ngIf="f['purpose'].errors['required']">Purpose is required</div>
                    
                    </div>
                  </div>
                  <!-- <div class="form-group col-sm-12 col-md-12 col-lg-4">
                    <label for="sstatus">Status<span class="requried-field">*</span></label>
                    <mat-slide-toggle class="slide-enable" [color]="isEnabled ? ' rgba(0, 128, 0, 0.5) ' : 'default'" (change)="enabled()">Enable</mat-slide-toggle>
                  </div> -->
                </div>
                <div class="formbutton">
          
               
                <button type="submit" class="btn btn-primary" (click)="onSubmit(labourForm.value)">Submit</button>
                <button type="submit" class="btn btn-danger btn2"  (click)="onCancel()">Cancel</button>
              </div>
              </form>
        </mat-tab>
        <mat-tab label="Supplier">
            <form [formGroup]="labourForm" >
                <div class="form-row">
                  <div class="form-group col-sm-12 col-md-4">
                      <label for="supplierDebit"> Debit (Project)<span class="requried-field">*</span></label>
                      <ng-autocomplete
                      [data]="projectName"
                      [searchKeyword]="keyword5"
                      placeholder="Enter the Supplier Name"
                      (selected)='selectEvent5($event)'
                      (inputChanged)='onChangeSearch5($event)'
                      (inputFocused)='onFocused5($event)'
                      historyIdentifier="projectName"
                      [itemTemplate]="itemTemplate5"
                      [notFoundTemplate]="notFoundTemplate">
                    </ng-autocomplete>
                  
                    <ng-template #itemTemplate5 let-item>
                      <a [innerHTML]="item.projectName"></a>
                    </ng-template>
                  
                    <ng-template #notFoundTemplate let-notFound>
                      <div [innerHTML]="notFound"></div>
                    </ng-template>
                    </div>
                  <div class="form-group col-sm-12 col-md-4">
                    <label for="SupplierName">Supplier Name<span class="requried-field">*</span></label>
                    <ng-autocomplete
                      [data]="supplierName"
                      [searchKeyword]="keyword3"
                      placeholder="Enter the Supplier Name"
                      (selected)='selectEvent3($event)'
                      (inputChanged)='onChangeSearch3($event)'
                      (inputFocused)='onFocused3($event)'
                      historyIdentifier="supplierName"
                      [itemTemplate]="itemTemplate3"
                      [notFoundTemplate]="notFoundTemplate">
                    </ng-autocomplete>
                  
                    <ng-template #itemTemplate3 let-item>
                      <a [innerHTML]="item.supplierName"></a>
                    </ng-template>
                  
                    <ng-template #notFoundTemplate let-notFound>
                      <div [innerHTML]="notFound"></div>
                    </ng-template>
                    <div *ngIf="submitted && f['supplierName'].errors" class="invalid-feedback">
                      <div *ngIf="f['supplierName'].errors['required']"> Name is required</div>
                    </div>
                  </div>
                  <div class="form-group col-sm-12 col-md-4">
                    <label for="totalAmount">Total Amount</label>
                    <input
                      type="number"
                      id="totalAmount"
                      placeholder="Enter totalAmount"
                      class="form-control"
                      formControlName="totalAmount"
                      [ngClass]="{ 'is-invalid': submitted && f['totalAmount'].errors }"
                    />
                    <div *ngIf="submitted && f['totalAmount'].errors" class="invalid-feedback">
                      <div *ngIf="f['totalAmount'].errors['required']">Total Amount is required</div>
                      
                    </div>
                  </div>
                  <div class="form-group col-sm-12 col-md-4">
                    <label for="tds">TDS<span class="requried-field">*</span></label>
                    <input
                      type="text"
                      id="tds"
                      placeholder="Enter tds"
                      class="form-control"
                      formControlName="tds"
                      [ngClass]="{ 'is-invalid': submitted && f['tds'].errors }"
                    />
                    <div *ngIf="submitted && f['tds'].errors" class="invalid-feedback">
                      <div *ngIf="f['tds'].errors['required']">tds is required</div>
                    </div>
                  </div>
            
                  <div class="form-group col-sm-12 col-md-4">
                    <label for="amount">Amount</label>
                    <input
                      type="text"
                      id="amount"
                      placeholder="Enter amount"
                      class="form-control"
                      formControlName="amount"
                      [ngClass]="{ 'is-invalid': submitted && f['amount'].errors }"
                    />
                    <div *ngIf="submitted && f['amount'].errors" class="invalid-feedback">
                      <div *ngIf="f['amount'].errors['amount']">Amount is required</div>
                      
                    </div>
                  </div>
                  <div class="form-group col-sm-12 col-md-4">
                    <label for="date">Date</label>
                    <input
                      type="text"
                      id="date"
                      placeholder="Enter date"
                      class="form-control"
                      formControlName="date"
                      [ngClass]="{ 'is-invalid': submitted && f['date'].errors }"
                    />
                    <div *ngIf="submitted && f['date'].errors" class="invalid-feedback">
                      <div *ngIf="f['date'].errors['required']">Date is required</div>
                      
                    </div>
                  </div>
            
                  <div class="form-group col-sm-12 col-md-4">
                    <label for="purpose"> Purpose <span class="requried-field">*</span></label>
                    <input
                      type="text"
                      id="purpose"
                      placeholder="Enter purpose"
                      class="form-control"
                      formControlName="purpose"
                      [ngClass]="{ 'is-invalid': submitted && f['purpose'].errors }"
                    />
                    <div *ngIf="submitted && f['purpose'].errors" class="invalid-feedback">
                      <div *ngIf="f['purpose'].errors['required']">Purpose is required</div>
                    
                    </div>
                  </div>
                  <!-- <div class="form-group col-sm-12 col-md-12 col-lg-4">
                    <label for="sstatus">Status<span class="requried-field">*</span></label>
                    <mat-slide-toggle class="slide-enable" [color]="isEnabled ? ' rgba(0, 128, 0, 0.5) ' : 'default'" (change)="enabled()">Enable</mat-slide-toggle>
                  </div> -->
                </div>
                <div class="formbutton">
          
               
                <button type="submit" class="btn btn-primary" (click)="onSubmit(labourForm.value)">Submit</button>
                <button type="submit" class="btn btn-danger btn2"  (click)="onCancel()">Cancel</button>
              </div>
              </form>
        </mat-tab>
        <mat-tab label="Partner">
            <form [formGroup]="labourForm" >
                <div class="form-row">
                  <div class="form-group col-sm-12 col-md-4">
                      <label for="partnerDebit"> Debit<span class="requried-field">*</span></label>
                      <ng-autocomplete
                      [data]="productName"
                      [searchKeyword]="keyword6"
                      placeholder="Enter the Supplier Name"
                      (selected)='selectEvent6($event)'
                      (inputChanged)='onChangeSearch6($event)'
                      (inputFocused)='onFocused6($event)'
                      historyIdentifier="productName"
                      [itemTemplate]="itemTemplate6"
                      [notFoundTemplate]="notFoundTemplate">
                    </ng-autocomplete>
                  
                    <ng-template #itemTemplate6 let-item>
                      <a [innerHTML]="item.productName"></a>
                    </ng-template>
                  
                    <ng-template #notFoundTemplate let-notFound>
                      <div [innerHTML]="notFound"></div>
                    </ng-template>
                     
                    </div>
                  <div class="form-group col-sm-12 col-md-4">
                    <label for="partnerName">Partner Name<span class="requried-field">*</span></label>
                    <ng-autocomplete
                      [data]="partnerName"
                      [searchKeyword]="keyword4"
                      placeholder="Enter the Employee Name"
                      (selected)='selectEvent4($event)'
                      (inputChanged)='onChangeSearch4($event)'
                      (inputFocused)='onFocused4($event)'
                      historyIdentifier="partnerName"
                      [itemTemplate]="itemTemplate4"
                      [notFoundTemplate]="notFoundTemplate">
                    </ng-autocomplete>
                  
                    <ng-template #itemTemplate4 let-item>
                      <a [innerHTML]="item.partnerName"></a>
                    </ng-template>
                  
                    <ng-template #notFoundTemplate let-notFound>
                      <div [innerHTML]="notFound"></div>
                    </ng-template>
                    <div *ngIf="submitted && f['labourName'].errors" class="invalid-feedback">
                      <div *ngIf="f['labourName'].errors['required']"> Name is required</div>
                    </div>
                  </div>
                  <div class="form-group col-sm-12 col-md-4">
                    <label for="totalAmount">Total Amount</label>
                    <input
                      type="number"
                      id="totalAmount"
                      placeholder="Enter totalAmount"
                      class="form-control"
                      formControlName="totalAmount"
                      [ngClass]="{ 'is-invalid': submitted && f['totalAmount'].errors }"
                    />
                    <div *ngIf="submitted && f['totalAmount'].errors" class="invalid-feedback">
                      <div *ngIf="f['totalAmount'].errors['required']">Total Amount is required</div>
                      
                    </div>
                  </div>
                  <div class="form-group col-sm-12 col-md-4">
                    <label for="tds">TDS<span class="requried-field">*</span></label>
                    <input
                      type="text"
                      id="tds"
                      placeholder="Enter tds"
                      class="form-control"
                      formControlName="tds"
                      [ngClass]="{ 'is-invalid': submitted && f['tds'].errors }"
                    />
                    <div *ngIf="submitted && f['tds'].errors" class="invalid-feedback">
                      <div *ngIf="f['tds'].errors['required']">tds is required</div>
                    </div>
                  </div>
            
                  <div class="form-group col-sm-12 col-md-4">
                    <label for="amount">Amount</label>
                    <input
                      type="text"
                      id="amount"
                      placeholder="Enter amount"
                      class="form-control"
                      formControlName="amount"
                      [ngClass]="{ 'is-invalid': submitted && f['amount'].errors }"
                    />
                    <div *ngIf="submitted && f['amount'].errors" class="invalid-feedback">
                      <div *ngIf="f['amount'].errors['amount']">Amount is required</div>
                      
                    </div>
                  </div>
                  <div class="form-group col-sm-12 col-md-4">
                    <label for="date">Date</label>
                    <input
                      type="text"
                      id="date"
                      placeholder="Enter date"
                      class="form-control"
                      formControlName="date"
                      [ngClass]="{ 'is-invalid': submitted && f['date'].errors }"
                    />
                    <div *ngIf="submitted && f['date'].errors" class="invalid-feedback">
                      <div *ngIf="f['date'].errors['required']">Date is required</div>
                      
                    </div>
                  </div>
            
                  <div class="form-group col-sm-12 col-md-4">
                    <label for="purpose"> Purpose <span class="requried-field">*</span></label>
                    <input
                      type="text"
                      id="purpose"
                      placeholder="Enter purpose"
                      class="form-control"
                      formControlName="purpose"
                      [ngClass]="{ 'is-invalid': submitted && f['purpose'].errors }"
                    />
                    <div *ngIf="submitted && f['purpose'].errors" class="invalid-feedback">
                      <div *ngIf="f['purpose'].errors['required']">Purpose is required</div>
                    
                    </div>
                  </div>
                  <!-- <div class="form-group col-sm-12 col-md-12 col-lg-4">
                    <label for="sstatus">Status<span class="requried-field">*</span></label>
                    <mat-slide-toggle class="slide-enable" [color]="isEnabled ? ' rgba(0, 128, 0, 0.5) ' : 'default'" (change)="enabled()">Enable</mat-slide-toggle>
                  </div> -->
                </div>
                <div class="formbutton">
          
               
                <button type="submit" class="btn btn-primary" (click)="onSubmit(labourForm.value)">Submit</button>
                <button type="submit" class="btn btn-danger btn2"  (click)="onCancel()">Cancel</button>
              </div>
              </form>
        </mat-tab>
    </mat-tab-group>
    
   
  </div>
  